<template>
  <div>
    <div class="row">
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('alumnus', 'list')">
        <router-link to="/alumnus" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
          <div class="card-body">
              <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">

                  <i class="fa fa-user-graduate font-white"></i>
              </span>
            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.alumnus_count || 0 }}</div>
            <div class="text-inverse-primary fw-semobold fs-7">Alumnus</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('vehicle', 'list')">
        <router-link to="/transport/vehicle" class="card-xl-stretch mb-xl-8 bg-primary  card hoverable">
          <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fa-solid fa-van-shuttle font-white"></i>
                </span>
            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.vehicle_count || 0 }}</div>
            <div class="text-inverse-primary fw-semobold fs-7">Vehicles</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('inventory-item', 'list')">
        <router-link to="/inventory-item" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
          <div class="card-body">
                <span class="svg-icon-gray-100 svg-icon svg-icon-3x mx-n1">
                  <i class="fa fa-boxes font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.inventory_count || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Inventories</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('contact-us', 'list')">
        <router-link to="/address-book" class="card-xl-stretch mb-xl-8 bg-primary card hoverable">
          <div class="card-body">
                <span class="svg-icon-gray-100 svg-icon svg-icon-3x mx-n1">
                  <i class="fa-solid fa-address-card font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.contact_count || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Contacts</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('user', 'list')">

        <router-link to="/kiosk_devices" class="card-xl-stretch mb-xl-8 bg-dark  card hoverable">
          <div class="card-body">
                <span class="svg-icon-gray-100 svg-icon svg-icon-3x mx-n1">
                  <i class="fa fa-tablet-alt font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.total_kiosk || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">KIOSK's</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('user-group', 'list')">
        <router-link :to="{name:'user-group'}" class="card-xl-stretch mb-xl-8 bg-primary card hoverable">
          <div class="card-body">
                <span class="svg-icon-gray-100 svg-icon svg-icon-3x mx-n1">
                  <i class="fa-solid fa-people-group font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.total_opened_job || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Groups</div>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
const dashboard=new DashboardService();
export default {
  name: "Counter1",
  data() {
    return {
      stats: {
        vehicle_count:0,
        contact_count:0,
        alumnus_count:0,
        total_kiosk: 0,
        inventory_count:0,
      },
      passed_out_student_stats:null,
      program_stats:[],
      library_stats:{
        total_book_counts:null,
        total_issued_counts:null,
      },
      open_admission_programs_lists:[],
      new_application_lists:[],
    }
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      dashboard
          .getStats2()
          .then((response) => {
            this.stats = response.data.adminStats;
          })
    },
  },
}
</script>