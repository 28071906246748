<template>
  <div>
    <v-card >
      <v-card-title>
      <v-row>
        <v-col md="8">
          <h4>Student by program</h4>
        </v-col>
        <v-col md="4" style="margin-top: 12px;">
          <v-select @change="getAllProgramStats" v-model="academicYearId" item-value="id" item-text="year" dense outlined :items="years"></v-select>
        </v-col>
      </v-row>

    </v-card-title>
      <v-card-text>
        <apexchart v-if="!loading"  width="100%" height="250" type="bar" :options="chartOptions" :series="series"></apexchart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
const academicYear=new AcademicYearService();
const dashboard=new DashboardService();
export default {
    name: 'ProgramStudents',
    data() {
      return {
        loading:false,
        programStudentStats:[],
        chartOptions: {
          chart: {
            id: 'student-by-program'
          },
          xaxis: {
            categories: []
          },
          noData: {
            text: "No data found",
            align: "center",
            verticalAlign: "middle",
          },
        },
        series: [{
          name: 'series-1',
          data: []
        }],

        years:[],
        academicYearId:null,
      }
    },
    mounted() {
      this.getAllAcademicYear();
    },
    methods:{
     getAllProgramStats(){
       this.loading=true;
       dashboard
           .getProgramStats({'academic_year_id':this.academicYearId})
           .then((response) => {
             let programStudentStats=response.data.programStudentStats;
             if(programStudentStats) {
              let program_name=[];
              let program_count=[];

              programStudentStats.forEach(function (item) {
                program_name.push(item.program_name);
                program_count.push(item.program_count);
              });
              this.chartOptions.xaxis.categories=program_name;
              this.series[0].data=program_count;
            }

           })
           .catch((err) => {

           })
           .finally(() => {
             this.loading=false;
           });
     },
     getAllAcademicYear(){
       academicYear
           .all()
           .then((response) => {
             this.years = response.data;
             if(this.years) {
               this.years.forEach(item => {
                 if(item.is_current_year) {
                   this.academicYearId = item.id;
                   this.getAllProgramStats();
                 }
               })
             }
            }).catch(() => { })
     },
  },
}
</script>
