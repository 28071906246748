<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Enrolment ID # {{enrollment.enrollment_id}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <template>
          <v-card>
            <v-tabs>
              <v-tab class="float-left">
                <v-icon left>fas fa-user-graduate</v-icon>Programme
              </v-tab>
              <v-tab class="float-left">
                <v-icon left>fas fa-user-graduate</v-icon>Applicant
              </v-tab>
              <v-tab class="float-left">
                <v-icon left>far fa-address-card</v-icon>Address
              </v-tab>
              <v-tab class="float-left">
                <v-icon left>fas fa-user-friends</v-icon>Parent Information
              </v-tab>
              <v-tab class="float-left">
                <v-icon left>fas fa-user-graduate</v-icon>Education Details
              </v-tab>
              <v-tab class="float-left">
                <v-icon left>fas fa-clipboard</v-icon>Additional Details
              </v-tab>
              <v-tab class="float-left">
                <v-icon left>fas fa-file-alt</v-icon>Documents
              </v-tab>
              <v-tab-item>
                <div class="card" flat>
                  <div class="card-body">
                    <div class="row card">
                      <table class="table table-striped">
                        <tr>
                          <th class="text-left">Programme Level</th>
                          <td class="text-left">
                            <span
                              class
                            >{{ enrollment.level ? enrollment.level.toUpperCase() : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left">Applied Programme</th>
                          <td class="text-left">
                            <span
                              class
                            >{{ enrollment.program ? formatString(enrollment.program) : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left">Submission Status</th>
                          <td class="text-left">
                            <span
                              :class="enrollment.is_submitted ? 'badge badge-info mr-2':'badge badge-warning'"
                            >{{ enrollment.is_submitted ? 'Complete' : 'Pending Submission' }}</span>
                          </td>
                        </tr>

                        <tr>
                          <th>
                            <b>Entrance Exam Date :</b>
                          </th>
                          <td>
                            <span class>{{ enrollment.exam_date ? enrollment.exam_date : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <b>Payment Status :</b>
                          </th>
                          <td>
                            <span
                              :class="enrollment.is_payment_completed ? 'badge badge-info mr-2':'badge badge-warning'"
                            >{{ enrollment.is_submitted ? 'Paid' : 'Pending Payment' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <b>Payment Method:</b>
                          </th>
                          <td>
                            <span
                              :class="enrollment.payment_method ? 'badge badge-info mr-2':'badge badge-warning'"
                            >{{ enrollment.payment_method ? enrollment.payment_method.toUpperCase() : 'N/A' }}</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="card" flat>
                  <div class="card-body">
                    <div class="row card">
                      <table class="table table-borderless">
                        <tr>
                          <th class="text-left">First Name</th>
                          <td
                            class="text-left"
                          >{{ enrollment.first_name ? enrollment.first_name : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Middle Name</th>
                          <td
                            class="text-left"
                          >{{ enrollment.middle_name ? enrollment.middle_name : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Last Name</th>
                          <td
                            class="text-left"
                          >{{ enrollment.last_name ? enrollment.last_name : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Email</th>
                          <td class="text-left">{{ enrollment.email ? enrollment.email : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Phone</th>
                          <td class="text-left">{{ enrollment.phone ? enrollment.phone : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">DOB (English)</th>
                          <td class="text-left">{{ enrollment.dob ? enrollment.dob : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">DOB (Nepali)</th>
                          <td class="text-left">{{ enrollment.dob_bs ? enrollment.dob_bs : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Gender</th>
                          <td
                            class="text-left"
                          >{{ enrollment.gender ? enrollment.gender.toUpperCase() : 'N/A' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Nationality</th>
                          <td
                            class="text-left"
                          >{{ enrollment.nationality ? enrollment.nationality.toUpperCase() : 'N/A' }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row v-if="addresses.length>0">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="text-left"
                        v-for="(address, index) of addresses"
                        :key="index"
                      >
                        <span
                          class="badge badge-sm badge-primary font-size-lg"
                        >{{ `${address.type.toUpperCase()}` }} ADDRESS</span>
                        <table class="table table-borderless">
                          <tr>
                            <th class="text-left">Address Line 1</th>
                            <td class="text-left">
                              {{
                              address.address_line_1 != '' && address.address_line_1 != null
                              ? address.address_line_1 : 'N/A'
                              }}
                            </td>

                            <th class="text-left">Address Line 2</th>
                            <td class="text-left">
                              {{
                              address.address_line_2 != '' &&
                              address.address_line_2 != null ? address.address_line_2 :
                              'N/A'
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Ward No.</th>
                            <td class="text-left">
                              {{
                              address.ward != '' && address.ward != null
                              ? address.ward : 'N/A'
                              }}
                            </td>

                            <th class="text-left">Area/Tole</th>
                            <td class="text-left">
                              {{
                              address.area != '' && address.area != null
                              ? address.area : 'N/A'
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Province</th>
                            <td class="text-left">
                              {{
                              address.state != '' && address.state != null
                              ? address.state : 'N/A'
                              }}
                            </td>

                            <th class="text-left">District</th>
                            <td class="text-left">
                              {{
                              address.district != '' &&
                              address.district != null ? address.district : 'N/A'
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Local Level</th>
                            <td class="text-left">
                              {{
                              address.city != '' && address.city != null
                              ? address.city : 'N/A'
                              }}
                            </td>

                            <th class="text-left">Country</th>
                            <td class="text-left">
                              {{
                              address.country != '' &&
                              address.country != null ? address.country : 'N/A'
                              }}
                            </td>
                          </tr>
                        </table>
                      </v-col>
                      <v-divider></v-divider>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <div class="card" flat v-if="parents.length>0">
                  <div class="card-body">
                    <div class="row card" v-for="(p, index) of parents" :key="index">
                      <table class="table table-striped">
                        <tr>
                          <th class="text-left">First Name</th>
                          <td class="text-left">{{ p.first_name }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Middle Name</th>
                          <td class="text-left">{{ (p.middle_name) ? p.middle_name : '-' }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Last Name</th>
                          <td class="text-left">{{ p.last_name }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Relationship</th>
                          <td class="text-left">{{ p.relation ? p.relation.toUpperCase() : "N/A" }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Profession</th>
                          <td
                            class="text-left"
                          >{{ p.profession ? p.profession.toUpperCase() : "N/A" }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Phone</th>
                          <td class="text-left">{{ p.phone }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Mobile</th>
                          <td class="text-left">{{ p.mobile }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Email</th>
                          <td class="text-left">{{ p.email }}</td>
                          <!-- <th class="text-left">Action</th>
                <td class="text-left">
                  <i class="text-danger fas fa-trash" @click="_delete(p.id)"></i>
                          </td>-->
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row v-if="educations.length>0">
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        class="text-center"
                        v-for="(edu, index) of educations"
                        :key="index"
                      >
                        <table class="table table-striped">
                          <tr>
                            <th>Level</th>
                            <td>
                              <span class="badge badge-secondary">{{ edu.board.toUpperCase() }}</span>
                            </td>
                            <th>Program</th>
                            <td>
                              <span class="badge badge-secondary">{{ edu.program }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Start Year</th>
                            <td>
                              <span class="badge badge-secondary">{{ edu.start_year.toUpperCase() }}</span>
                            </td>
                            <th>Pass Year</th>
                            <td>
                              <span class="badge badge-secondary">{{ edu.pass_year }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Marks Obtain</th>
                            <td>
                              <span class="badge badge-secondary">{{ edu.mark_obtain }}</span>
                            </td>
                            <th v-if="edu.university">University</th>
                            <td v-if="edu.university">
                              <span class="badge badge-secondary">{{ formatString(edu.university) }}</span>
                            </td>
                          </tr>
                        </table>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-row v-else>
                      <v-col>
                        <v-card-text>
                          <P>No Education Details Provided.</P>
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <table class="table table-striped">
                          <tr>
                            <th>Scholarship Offered</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info && info.scholarship_offered"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Grade Repeated</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info &&  info.grade_repeated"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Health Issues</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info &&  info.has_health_issues"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Routine Medicine</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info &&  info.routine_medicine"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Special Needs</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info &&  info.special_needs"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Recidential Facility</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info &&  info.residential_facility"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Transport Facility</th>
                            <td>
                              <span
                                class="badge badge-primary"
                                v-if="info &&  info.transport_facility"
                              >YES</span>
                              <span class="badge badge-danger" v-else>NO</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Class Shift</th>
                            <td>
                              <span
                                class="badge badge-secondary"
                                v-if="info &&  info.class_shift"
                              >{{ info && info.class_shift.toUpperCase() }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>Interested Subject</th>
                            <td>
                              <span
                                class="badge badge-secondary"
                                v-if="info &&  info.interested_subject"
                              >{{ info && info.interested_subject.toUpperCase() }}</span>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text v-if="documents.length>0">
                    <div class="row">
                      <v-btn
                        depressed
                        class="text-primary btn btn-primary p-5"
                        :loading="isBusy"
                        href
                        @click.prevent="downloadZip"
                      >Download Zip</v-btn>
                      <div class="col-2" v-for="(doc, i) of documents" :key="i">
                        <v-card class="mx-auto my-12">
                          <img
                            v-if="checkFileType(doc.image_path.thumb)"
                            :src="doc.image_path.thumb"
                            alt
                            class="img-thumbnail"
                          />
                          <img v-else src="/media/document.png" alt class="img-thumbnail" />

                          <v-card-title class="d-flex align-center justify-space-between">
                            {{ doc.photo_type }}
                            <a
                              :href="doc.image_path['real']"
                              download
                              class="text-right"
                            >
                              <v-icon>fas fa-download</v-icon>
                            </a>
                          </v-card-title>
                        </v-card>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-text v-else>
                    <P>No Documents Uploaded.</P>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import AddressService from "@/core/services/user/UserAddressService";
import UserParentService from "@/core/services/user/UserParentService";
import UserEducationService from "@/core/services/user/UserEducationService";
import UserInfoService from "@/core/services/user/UserInfoService";
import UserDocumentService from "@/core/services/user/UserDocumentService";

const enrollmentService = new UserEnrollmentService();
const addressService = new AddressService();
const parentService = new UserParentService();
const educationService = new UserEducationService();
const infoService = new UserInfoService();
const documentService = new UserDocumentService();

export default {
  name: "enrollment-detail",
  props: ["dialog", "enrollment_id"],
  data() {
    return {
      levels: [],
      notifications: false,
      sound: true,
      isBusy: false,
      widgets: false,
      tab: null,
      enrollment: {},
      addresses: [],
      parents: [],
      educations: [],
      documents: [],
      info: {},
      count_api_call: 0,
      imageFiles: ["jpg", "jpeg", "png", "gif"],
      tabs: [
        "Student",
        "Address",
        "Parent",
        "Education",
        "Additional",
        "Documents"
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    fileName() {
      return this.enrollment.first_name
        ? this.enrollment.first_name
        : "file" + this.enrollment.last_name
        ? this.enrollment.last_name
        : "name" + ".zip";
    }
  },
  updated() {
    if (this.enrollment_id && this.count_api_call == 0) {
      this.getEnrollmentDetail();
    }
  },
  mounted() {},
  methods: {
    checkFileType(file) {
      let fileExtension = file.split(".")[2];
      let image = this.imageFiles.indexOf(fileExtension) > -1;
      return image;
    },
    formatString(string) {
      return string
        .split("_")
        .join(" ")
        .toUpperCase();
    },

    closeDialog() {
      this.count_api_call = 0;
      this.$emit("close_dialog");
    },
    getEnrollmentDetail() {
      enrollmentService
        .getById(this.enrollment_id)
        .then(response => {
          this.enrollment = response.data.enrollment;
          this.count_api_call = 1;
          this.getAddress();
          this.getparents();
          this.getEducations();
          this.getDocuments();
          this.getInfo();
        })
        .catch(error => {
          //console.log(error);
        });
    },
    getAddress() {
      addressService
        .paginate(this.enrollment_id)
        .then(response => {
          this.addresses = response.data.data;
        })
        .catch(error => {
          //console.log(error);
        });
    },
    getparents() {
      parentService
        .paginate(this.enrollment_id)
        .then(response => {
          this.parents = response.data.data;
        })
        .catch(error => {
          //console.log(error);
        });
    },
    downloadZip() {
      this.isBusy = true;
      documentService
        .zip(this.enrollment_id)
        .then(res => {
          var fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          var link = document.createElement("a");
          link.href = fileUrl;
          link.setAttribute(
            "download",
            this.enrollment.first_name + this.enrollment.last_name + ".zip"
          );
          document.body.appendChild(link);
          link.click();
          this.isBusy = false;
        })
        .catch(error => {
          // console.log(error);
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },
    getEducations() {
      educationService
        .paginate(this.enrollment_id)
        .then(response => {
          this.educations = response.data.data;
        })
        .catch(error => {
          //console.log(error);
        });
    },
    getInfo() {
      infoService
        .getByUser(this.enrollment_id)
        .then(response => {
          this.info = response.data.info;
        })
        .catch(error => {
          //console.log(error);
        });
    },
    getDocuments() {
      documentService
        .paginate(this.enrollment_id)
        .then(response => {
          this.documents = response.data.data;
        })
        .catch(error => {
          //console.log(error);
        });
    }
  }
};
</script>
