<template>
    <v-card class="text-center py-5 px-4">
        <v-avatar color="bg-primary" class="rounded-circle">
            <v-icon class="text-white">
                mdi-alert
            </v-icon>
        </v-avatar>
        <v-card-title class="justify-content-center">Counter Title</v-card-title>
        <v-card-text class="text-primary h2">5</v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'Counter',
    }
</script>