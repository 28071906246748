import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class UserInfoService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/enrollment`;

      }

      paginate(userId) {
            let url = `${this.#api}/${userId}/info`;
            return apiService.get(url);
      }

      getByUser(userId){
            let url = `${this.#api}/${userId}/info`;
            return apiService.get(url);
      }

      update(userId,infoId, data) {
            let url = `${this.#api}/${userId}/info/${infoId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
            let url = `${this.#api}/${userId}/info`
            return apiService.post(url, data);

      }

      show(userId, infoId) {
            let url = `${this.#api}/${userId}/info/${infoId}`
            return apiService.get(url);
      }

      delete(userId, infoId) {
            let url = `${this.#api}/${userId}/info/${infoId}`
            return apiService.delete(url);
      }
}
