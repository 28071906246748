<template>
  <div class="row">
    <div class="col-md-6">
      <router-link to="/exams">
        <div class="card  card-stretch dashboard-count-box">
          <a href="#" class="card-title font-weight-bolder text-dark-75 font-size-h5 mt-2 mb-2 ml-3 text-hover-primary">
            Today visitors</a>

          <v-list-item v-show="visitors && visitors.length > 0"  v-for="(visitor,index) in visitors" :key="index">
            <v-list-item-content>
              <v-list-item-title>
                  <span class="font-weight-medium h6">
                      {{visitor.full_name}}
                  </span>&nbsp;-&nbsp;
                <span class="font-weight-medium">{{visitor.entry_time_format}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span v-show="visitors.length == 0" class="mt-3 mb-3 ml-4">
            No visitors found.
          </span>
        </div>
      </router-link>
    </div>
  </div>

</template>
<script>
import UserService from "@/core/services/user/UserService";
const userService=new UserService;
export default {
  data(){
    return{
      visitors:[],
    }
  },
  methods: {
    getTodayVisitor(){
      userService
          .getVisitorStats()
          .then((response) => {
            this.visitors=response.data.visitors;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
  mounted() {
    this.getTodayVisitor();
  }
}
</script>