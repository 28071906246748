import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class UserEducationService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/enrollment`;
      }

      paginate(userId) {
            let url = `${this.#api}/${userId}/address`;
            return apiService.get(url);
      }

      update(userId,addressId, data) {
            let url = `${this.#api}/${userId}/address/${addressId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
            let url = `${this.#api}/${userId}/address`
            return apiService.post(url, data);

      }

      show(userId, addressId) {
            let url = `${this.#api}/${userId}/address/${addressId}`
            return apiService.get(url);
      }

      delete(userId, addressId) {
            let url = `${this.#api}/${userId}/address/${addressId}`
            return apiService.delete(url);
      }
}
