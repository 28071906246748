import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class ProgramService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/program`;
      }

      paginate(data=null, index=1) {
            let url = `${this.#api}`;
            if (index != null)
                  url = `${url}?page=${index}`;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }

      getByLevelId(levelId) {
            let url = `${this.#api}-level/${levelId}`;
            return apiService.get(url);
      }

      getAllByLevelId(levelId) {
            let url = `${this.#api}/${levelId}/all`;
            return apiService.get(url);
      }


      all() {
            let url = `${this.#api}s/all`;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}/update`
            return apiService.post(url,data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

      getByAbstract(program) {
            let url = `${this.#api}/${program}/detail`
            return apiService.get(url);
      }
      generateProgramPDF(programId,data={}){
            let url = `${this.#api}/${programId}/generate-pdf`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            window.open(url);
      }





}
