<template>
  <div>
    <div class="border-graph">
      <h4>Online inquiries</h4>
      <apexchart v-if="!loading" width="100%" height="250px" type="donut" :options="chartOptions" :series="series"></apexchart>
    </div>

  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";

const dashboard=new DashboardService();
    export default {
        name: 'OnlineInquries',
        data: function() {
          return {
            loading:false,
            chartOptions: {
              chart: {
                id: 'vuechart-example',
              },
              labels: [],
              dataLabels: {
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex]
                },
              },
            },
            series: [],
          }
        },
      methods:{

        getAllOnlineInquiryStats(){
          this.loading=true;
          dashboard
              .getOnlineInquiriesStats()
              .then((response) => {
                let onlineInquiryStats=response.data.online_inquiry_stats;
                let label=[];
                let count=[];

                onlineInquiryStats.forEach(function (item) {
                  label.push(item.label);
                  count.push(item.value);

                });
                this.chartOptions.labels=label;
                this.series=count;

              })
              .catch((err) => {

              })
              .finally(() => {
                this.loading=false;
              });
        },
      },
      mounted() {
        this.getAllOnlineInquiryStats();
      }
    }
</script>