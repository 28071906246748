import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class UserEnrollmentService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    paginate(data, index) {
        let url = `${this.#api}/enrollment-list`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
            if (index)
                url = url + "&page=" + index
        } else {
            url = url + "?page=" + index
        }
        return apiService.get(url);
    }

    getEnrollmentCandidates(data, index) {
        let url = `${this.#api}/enrollment-candidate-list`;
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    userEnroll() {
        let url = `${this.#api}/enrollment-user-list`;
        return apiService.get(url);
    }

    getByUser(userId) {
        let url = `${this.#api}/${userId}/enrollment`;
        return apiService.get(url);
    }

    update(userId, enrollmentId, data) {
        let url = `${this.#api}/${userId}/enrollment/${enrollmentId}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/enrollment`
        return apiService.post(url, data);

    }
    createEnrolmentInfo(data){
        let url = `${this.#api}/enrollment/create`
        return apiService.post(url, data);
    }

    show(userId, enrollmentId) {
        let url = `${this.#api}/${userId}/enrollment/${enrollmentId}`
        return apiService.get(url);
    }

    getById(enrollmentId) {
        let url = `${this.#api}/enrollment-id/${enrollmentId}`
        return apiService.get(url);
    }

    delete(userId, enrollmentId) {
        let url = `${this.#api}/${userId}/enrollment/${enrollmentId}`
        return apiService.delete(url);
    }

    enrollmentStatus(userId, enrollmentId) {
        let url = `${this.#api}/${userId}/enrollment-status/${enrollmentId}`
        return apiService.get(url);
    }

    getStats() {
        let url = `${this.#api}/enrollment-stats`
        return apiService.get(url);
    }

    convertAdToBs(data) {
        let url = `${this.#api}/convert-ad-to-bs`
        return apiService.post(url, data);
    }

    convertBsToAd(data) {
        let url = `${this.#api}/convert-bs-to-ad`
        return apiService.post(url, data);
    }

    selectedCandidate(id) {
        let url = `${this.#api}/selected-candidate/by/entrance/${id}`
        return apiService.get(url);
    }

    updateEnrollmentUser(enrollmentId, data) {
        let url = `${this.#api}/enrollment/${enrollmentId}/update`
        return apiService.put(url, data);

    }

    createNewPreRegistration(data) {
        let url = `${this.#api}/pre-registration/create/new`;
        return apiService.post(url, data);
    }

    updatePreRegistration(id, data) {
        let url = `${this.#api}/pre-registration/${id}/update`
        return apiService.post(url, data);
    }
    updateUserEnrolmentInfo(id, data) {
        let url = `${this.#api}/user_enrollment_infos/${id}/update`
        return apiService.post(url, data);
    }
    saveUserEnrolmentInfo(data) {
        let url = `${this.#api}/user_enrollment_infos/create`;
        return apiService.post(url, data);
    }

    importStudents(data) {
        let url = `${this.#api}/pre-registration/import/students`
        return apiService.post(url, data);
    }

    importResult(data) {
        let url = `${this.#api}/pre-registration/import/result`
        return apiService.post(url, data);
    }

    updateEnrollmentStatus(preRegistrationId, entranceId, data) {
        let url = `${this.#api}/pre-registration/${preRegistrationId}/entrance/${entranceId}/update/status`
        return apiService.post(url, data);
    }

    printOfferLetter(data) {
        let url = `${this.#api}/pre-registration/print/offer-letter`
        return apiService.post(url, data);
    }
    previewPDF(enrolmentId,data={})
    {
        let url = `${this.#api}/enrollment-id/${enrolmentId}/preview-in-pdf`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }

    initiatePrintJob(data) {
        let url = `${this.#api}/pre-registration/print/print-jobs`
        return apiService.post(url, data);
    }

    getUnAssignedCandidates(data) {
        let url = `${this.#api}/pre-registration/un-assigned/candidates`
        return apiService.post(url, data);
    }

    setInterviewDate(data) {
        let url = `${this.#api}/pre-registration/set-interview/dates`
        return apiService.post(url, data);
    }

    setEntranceDateAndTime(data) {
        let url = `${this.#api}/pre-registration/set-entrance/date-and-time`
        return apiService.post(url, data);
    }

    approveApplication(id) {
        let url = `${this.#api}/pre-registration/${id}/approve/application`
        return apiService.get(url);
    }

    markAsSubmitted(id) {
        let url = `${this.#api}/pre-registration/${id}/mark-as/submitted`
        return apiService.get(url);
    }

    uploadFiles(id, data) {
        let url = `${this.#api}/pre-registration/${id}/upload/files`
        return apiService.post(url, data);
    }

    markAsPaid(id,data) {
        let url = `${this.#api}/pre-registration/${id}/mark-as/paid`;
        return apiService.post(url,data);
    }

    disapprove(id) {
        let url = `${this.#api}/pre-registration/${id}/disapprove`
        return apiService.post(url);
    }
    updateMarks(id,data={}) {
        let url = `${this.#api}/user_enrollment_infos/${id}/update-marks`;
        return apiService.post(url,data);
    }
    resendAdmitCardMail(enrollmentId){
        let url = `${this.#api}/pre-registration/${enrollmentId}/resend-mail/admit-card`;
        return apiService.get(url);
    }
    sendOfferLetterMailWithBackground(enrollmentId){
        let url = `${this.#api}/pre-registration/${enrollmentId}/send-mail/background-offer-letter`;
        return apiService.get(url);
    }
    sendEnrolmentPinInMail(enrollmentId){
        let url = `${this.#api}/pre-registration/${enrollmentId}/send-mail/enrolment-pin`;
        return apiService.get(url);
    }
    sendEnrolmentSymbolNoInMail(enrollmentId){
        let url = `${this.#api}/pre-registration/${enrollmentId}/send-mail/symbol-no`;
        return apiService.get(url);
    }
    changeOfferLetterVisible(id) {
        let url = `${this.#api}/pre-registration/${id}/change/offer-letter/visible`;
        return apiService.get(url);
    }
    acceptEnrollmentOffer(id,data={}) {
        let url = `${this.#api}/pre-registration/${id}/accept-enrollment-offer`;
        return apiService.post(url,data);
    }
    getAvailableClassrooms(id){
        let url = `${this.#api}/pre-registration/${id}/get/available-classrooms`;
        return apiService.get(url);
    }
    manageBulkEntranceRoom(data={}) {
        let url = `${this.#api}/pre-registration/manage-bulk-entrance-room`;
        return apiService.post(url,data);
    }
    sendOrientationInvitation(enrollmentId){
        let url = `${this.#api}/pre-registration/${enrollmentId}/send-orientation-invitation-mail`;
        return apiService.get(url);
    }
    downloadBulkAdmitCard(data={})
    {
        let url = `${this.#api}/pre-registration/download/bulk/admit-card`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        window.open(url);
    }

    updateProgram(id, programId, data={})
    {
        let url = `${this.#api}/enrollment/${id}/update-change/${programId}/program`;
        return apiService.post(url,data);
    }
    markEnrollmentAdmitted(id,data={}) {
        let url = `${this.#api}/pre-registration/${id}/mark-as-admitted`;
        return apiService.post(url,data);
    }

}
