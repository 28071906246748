import Apiservice from '@/core/services/api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class UserDocumentService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/enrollment`;
    }

    paginate(userId) {
        let url = `${this.#api}/${userId}/document`;
        return apiService.get(url);
    }

    update(userId, documentId, data) {
        let url = `${this.#api}/${userId}/document/${documentId}`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/document`
        return apiService.post(url, data);

    }

    show(userId, documentId) {
        let url = `${this.#api}/${userId}/document/${documentId}`
        return apiService.get(url);
    }


    zip(userId) {
        let url = `${this.#api}/document/${userId}/zip`
        return apiService.getFile(url);
    }

    delete(userId, documentId) {
        let url = `${this.#api}/${userId}/document/${documentId}`
        return apiService.delete(url);
    }
}
