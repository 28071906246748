import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class DashboardService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}`;
    }
    getStats() {
        let url = `${this.#api}user/stats`
        return apiService.get(url);
    }
    getStats2() {
        let url = `${this.#api}user/stats-2`
        return apiService.get(url);
    }
    getLibraryStat() {
        let url = `${this.#api}user/library/stats/admin`;
        return apiService.get(url);
    }

    getFinanceStat() {
        let url = `${this.#api}user/finance/stat`;
        return apiService.get(url);
    }
    getProgramStats(data={}) {
        let url = `${this.#api}user/programs/stats`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getOnlineInquiriesStats() {
        let url = `${this.#api}user/online-inquiry/stats`;
        return apiService.get(url);
    }
    getExamStats() {
        let url = `${this.#api}user/exam/stats`
        return apiService.get(url);
    }
    getVisitorStats() {
        let url = `${this.#api}user/visitor/stats`;
        return apiService.get(url);
    }
    getAdmissionStats() {
        let url = `${this.#api}user/admission/stats`
        return apiService.get(url);
    }
    getEventStats() {
        let url = `${this.#api}user/event/stats`
        return apiService.get(url);
    }


}