<template>
  <div>


    <div class="row">
<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 4&ndash;&gt;-->
<!--        <router-link to="/students">-->
<!--          <div class="card  card-stretch dashboard-count-box">-->
<!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--            <div class="card-body d-flex align-items-center py-0">-->
<!--              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--                <a href="#"-->
<!--                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">-->
<!--                  Students</a>-->
<!--                <span class=" font-size-h6  font-size-md">-->
<!--                <span class="font-weight-medium">Total:</span> <span class="font-weight-bold">{{ stats.total_student || 0 }}</span> |-->
<!--                <span class="font-weight-medium"> Active:</span> <span class="font-weight-bold">{{ stats.total_active_student || 0 }}</span> |-->
<!--                <span class="font-weight-medium"> Inactive:</span> <span class="font-weight-bold">{{ stats.total_in_active_student || 0 }}</span>-->
<!--              </span>-->
<!--                <span class="mt-2 font-size-h6  font-size-md">-->
<!--                <span class="font-weight-medium"> Passed out total student:</span> <span class="font-weight-bold">{{ passed_out_student_stats || 0 }}</span>-->
<!--              </span>-->
<!--                <div class="mt-2">-->
<!--                  <label for="">Program wise total student</label>-->
<!--                  <ul class="list-group list-group-flush" v-for="(stats,index) in program_stats" :key="index">-->
<!--                    <li class="list-group-item"><span class="font-weight-medium">{{stats.program_name}}</span> :  <span class="font-weight-bold">{{stats.program_count}}</span></li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </div>-->
<!--              &lt;!&ndash;                        <img src="media/education/icons/students-flat.png" alt="" class="align-self-end h-70px">&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Body&ndash;&gt;-->
<!--          </div>-->
<!--        </router-link>-->

<!--        &lt;!&ndash;end::Stats Widget 4&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 5&ndash;&gt;-->
<!--        <router-link to="/teachers">-->
<!--          <div class="card  card-stretch dashboard-count-box">-->
<!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--            <div class="card-body d-flex align-items-center py-0">-->
<!--              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--                <a href="#"-->
<!--                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Total-->
<!--                  Teachers</a>-->
<!--                &lt;!&ndash;              <span class="font-weight-bold font-size-h4  font-size-lg">{{ stats.total_teacher || 0 }}</span>&ndash;&gt;-->
<!--                <span class=" font-size-h6  font-size-md">-->
<!--                              Total: <span class="font-weight-bold">{{ stats.total_teacher || 0 }}</span> |-->
<!--                              Active: <span class="font-weight-bold">{{ stats.total_active_teacher || 0 }}</span> |-->
<!--                              Inactive: <span class="font-weight-bold">{{ stats.total_in_active_teacher || 0 }}</span>-->
<!--                            </span>-->

<!--              </div>-->
<!--              &lt;!&ndash;                    <img src="media/education/icons/teachers-flat.png" alt="" class="align-self-end h-70px">&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Body&ndash;&gt;-->
<!--          </div>-->
<!--        </router-link>-->
<!--        &lt;!&ndash;end::Stats Widget 5&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 6&ndash;&gt;-->
<!--        <router-link to="/user/admin">-->

<!--          <div class="card card-stretch dashboard-count-box">-->
<!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--            <div class="card-body d-flex align-items-center py-0">-->
<!--              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--                <a href="#"-->
<!--                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Total-->
<!--                  Staffs</a>-->
<!--                <span class=" font-size-h6  font-size-md">-->
<!--                              Total: <span class="font-weight-bold">{{ stats.total_staff || 0 }}</span> |-->
<!--                              Active: <span class="font-weight-bold">{{ stats.total_active_staff || 0 }}</span> |-->
<!--                              Inactive: <span class="font-weight-bold">{{ stats.total_in_active_staff || 0 }}</span>-->
<!--                            </span>-->

<!--              </div>-->
<!--              &lt;!&ndash;                    <img src="media/education/icons/stafs-flat.png" alt="" class="align-self-end h-70px">&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Body&ndash;&gt;-->
<!--          </div>-->
<!--        </router-link>-->
<!--        &lt;!&ndash;end::Stats Widget 6&ndash;&gt;-->
<!--      </div>-->

<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 5&ndash;&gt;-->
<!--        <router-link to="/program">-->
<!--          <div class="card  card-stretch dashboard-count-box">-->
<!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--            <div class="card-body d-flex align-items-center py-0">-->
<!--              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--                <a href="#"-->
<!--                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Total-->
<!--                  Programs</a>-->
<!--                <span class=" font-size-h6  font-size-md">-->
<!--                              Total: <span class="font-weight-bold">{{ stats.total_program || 0 }}</span> |-->
<!--                              Active: <span class="font-weight-bold">{{ stats.total_active_program || 0 }}</span> |-->
<!--                              Inactive: <span class="font-weight-bold">{{ stats.total_in_active_program || 0 }}</span>-->
<!--                            </span>-->
<!--              </div>-->
<!--              &lt;!&ndash;                    <img src="media/education/icons/programs-flat.png" alt="" class="align-self-end h-70px">&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Body&ndash;&gt;-->
<!--          </div>-->
<!--        </router-link>-->
<!--        &lt;!&ndash;end::Stats Widget 5&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 4&ndash;&gt;-->
<!--        <div class="card  card-stretch dashboard-count-box">-->
<!--          &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--          <div class="card-body d-flex align-items-center py-0">-->
<!--            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--              <a href="#"-->
<!--                 class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Total-->
<!--                Parents</a>-->
<!--              <span class="font-weight-bold font-size-h4  font-size-lg">{{ stats.total_parent || 0 }}</span>-->

<!--            </div>-->
<!--            &lt;!&ndash;                    <img src="media/education/icons/parents-flat.png" alt="" class="align-self-end h-70px">&ndash;&gt;-->
<!--          </div>-->
<!--          &lt;!&ndash;end::Body&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Stats Widget 4&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 6&ndash;&gt;-->
<!--        <router-link to="/career">-->
<!--          <div class="card  card-stretch dashboard-count-box">-->
<!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--            <div class="card-body d-flex align-items-center py-0">-->
<!--              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--                <a href="#"-->
<!--                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Job-->
<!--                  Openings</a>-->
<!--                <span class="font-weight-bold font-size-h4  font-size-lg">{{ stats.total_opened_job || 0 }}</span>-->

<!--              </div>-->
<!--              &lt;!&ndash;                    <img src="media/education/icons/job-search-flat.png" alt="" class="align-self-end h-70px">&ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Body&ndash;&gt;-->
<!--          </div>-->
<!--        </router-link>-->
<!--        &lt;!&ndash;end::Stats Widget 6&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="col-xl-12">-->
<!--        &lt;!&ndash;begin::Stats Widget 6&ndash;&gt;-->
<!--        <router-link to="/library/all-books">-->
<!--          <div class="card  card-stretch dashboard-count-box">-->
<!--            &lt;!&ndash;begin::Body&ndash;&gt;-->
<!--            <div class="card-body d-flex align-items-center py-0">-->
<!--              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">-->
<!--                <a href="#"-->
<!--                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Library</a>-->
<!--                <span class=" font-size-h6 font-size-md">-->
<!--                  Total books: <span class="font-weight-bold">{{ library_stats.total_book_counts || 0 }}</span> |-->
<!--                  Issued book: <span class="font-weight-bold">{{ library_stats.total_issued_counts || 0 }}</span> |-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;end::Body&ndash;&gt;-->
<!--          </div>-->
<!--        </router-link>-->
<!--        &lt;!&ndash;end::Stats Widget 6&ndash;&gt;-->
<!--      </div>-->
      <div class="col-xl-12" v-if="open_admission_programs_lists.length>0">
        <!--begin::Stats Widget 6-->
        <router-link to="/program">
          <div class="card  card-stretch dashboard-count-box">
            <!--begin::Body-->
            <div class="card-body d-flex align-items-center py-0">
              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                <a href="#"
                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Open admission programs list</a>
                <div class="mt-2">

                  <ul class="list-group list-group-flush" v-for="(admission,index) in open_admission_programs_lists" :key="index">
                    <li class="list-group-item"><span class="font-weight-medium">{{admission.title}}</span> </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </router-link>
        <!--end::Stats Widget 6-->
      </div>


    </div>
    <div class="row">
      <div class="col-md-6">
        <v-row class="text-center">
          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('user', 'list')"
                 class="text-center">
            <router-link to="/students">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/students.png" style="height: 30px"/>
                <p>Students</p>
              </div>
            </router-link>
          </v-col>
          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('user', 'list')"
                 class="text-center">
            <router-link to="/teachers">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/teacher.png" style="height: 30px"/>
                <p>Teachers</p>
              </div>
            </router-link>
          </v-col>
          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('user', 'list')" class="text-center">
            <router-link to="/user/admin">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/staff.png" style="height: 30px"/>
                <p>Staffs</p>
              </div>
            </router-link>
          </v-col>
          <!--          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('finance', 'list')" class="text-center">-->
          <!--            <a href="#" v-b-tooltip.hover.right="'Coming soon...'">-->
          <!--              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
          <!--                <img src="media/education/icons/finance.png" style="height: 30px"/>-->
          <!--                <p>Finance</p>-->
          <!--              </div>-->
          <!--            </a>-->
          <!--          </v-col>-->
<!--          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('enrolments', 'list')" class="text-center">-->
<!--            <router-link :to="{name:'enrollment-list'}">-->
<!--              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
<!--                <img src="media/education/icons/enrolments.png" style="height: 30px"/>-->
<!--                <p>Enrolments</p>-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </v-col>-->


          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('academic-class', 'list')"
                 class="text-center">
            <router-link :to="{name: 'academic-class'}">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/class.png" style="height: 30px"/>
                <p>Classrooms</p>
              </div>
            </router-link>
          </v-col>
          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('exam', 'list')"
                 class="text-center">
            <router-link :to="{name:'exams'}">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/exam.png" style="height: 30px"/>
                <p>Exams</p>
              </div>
            </router-link>
          </v-col>
          <!--                <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('user', 'list')"-->
          <!--                       class="text-center">-->
          <!--                  <router-link :to="{name:'results'}">-->
          <!--                    <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
          <!--                      <img src="media/education/icons/reports.png" style="height: 30px"/>-->
          <!--                      <p>Results</p>-->
          <!--                    </div>-->
          <!--                  </router-link>-->
          <!--                </v-col>-->
          <!--          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('attendance', 'list')" class="text-center">-->
          <!--            <router-link :to="{name:'class-attendance'}">-->
          <!--              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
          <!--                <img src="media/education/icons/attendance.png" style="height: 30px"/>-->
          <!--                <p>Attendance</p>-->
          <!--              </div>-->
          <!--            </router-link>-->
          <!--          </v-col>-->
          <!--          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('report', 'list')" class="text-center">-->
          <!--            <a href="#" v-b-tooltip.hover.right="'Coming soon...'">-->
          <!--              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
          <!--                <img src="media/education/icons/report.png" style="height: 30px"/>-->
          <!--                <p>Reports</p>-->
          <!--              </div>-->
          <!--            </a>-->
          <!--          </v-col>-->
          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('program', 'list')"
                 class="text-center">
            <router-link to="/program">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/graduation-cap.png" style="height: 30px"/>
                <p>Programs</p>
              </div>
            </router-link>
          </v-col>

          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('job', 'list')"
                 class="text-center">
            <router-link :to="{name:'career'}">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/resume.png" style="height: 30px"/>
                <p>Jobs</p>
              </div>
            </router-link>
          </v-col>
<!--          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('academic-year', 'list')"-->
<!--                 class="text-center">-->
<!--            <router-link :to="{name:'academic-year'}">-->
<!--              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
<!--                <img src="media/education/icons/academic-year.png" style="height: 30px"/>-->
<!--                <p>Batch</p>-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </v-col>-->
<!--          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('department', 'list')"-->
<!--                 class="text-center">-->
<!--            <router-link :to="{name:'department'}">-->
<!--              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">-->
<!--                <img src="media/education/icons/department.png" style="height: 30px"/>-->
<!--                <p>Departments</p>-->
<!--              </div>-->
<!--            </router-link>-->
<!--          </v-col>-->

          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('course', 'list')" class="text-center">
            <router-link :to="{name:'courses'}">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/course.png" style="height: 30px"/>
                <p>Courses</p>
              </div>
            </router-link>
          </v-col>
          <v-col cols="2" sm="2" md="4" v-if="checkIsAccessible('id-card', 'list')" class="text-center">
            <router-link :to="{name:'id-card'}">
              <div class="col-md-12 dashboard-quick-icons border border-gray m-1">
                <img src="media/education/icons/id-card.png" style="height: 30px"/>
                <p>ID cards</p>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </div>
      <div class="col-md-6">
        <!--begin::Stats Widget 6-->
        <router-link to="/program">
          <div class="card  card-stretch dashboard-count-box">
            <!--begin::Body-->
            <div class="card-body d-flex align-items-center py-0">
              <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                <a href="#"
                   class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Total new applications</a>
                <div class="mt-2">

                  <ul class="list-group list-group-flush" v-for="(application,index) in new_application_lists" :key="index">
                    <li class="list-group-item"><span class="font-weight-medium">{{application.program_name}}</span> :  <span class="font-weight-bold">{{application.program_count}}</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </router-link>
        <!--end::Stats Widget 6-->
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/core/services/user/UserService";

const userService = new UserService();
export default {
  name: "DashboardStats",
  data() {
    return {
      stats: {
        total_opened_job: null,
        total_parent: null,
        total_program: null,
        total_staff: null,
        total_student: null,
        total_teacher: null,
      },
      passed_out_student_stats:null,
      program_stats:[],
      library_stats:{
        total_book_counts:null,
        total_issued_counts:null,
      },
      open_admission_programs_lists:[],
      new_application_lists:[],
    }
  },
  mounted() {
    this.getStats();
    this.getAdmissionStats();
  },
  methods: {
    getStats() {
      userService
          .getStats()
          .then((response) => {
            this.stats = response.data.adminStats;
            this.program_stats = response.data.programStudentStats;
            this.passed_out_student_stats = response.data.passed_out_student_stats;
            this.library_stats=response.data.library_counts;

       })
    },
    getAdmissionStats(){
      userService
          .getAdmissionStats()
          .then((response) => {
            this.open_admission_programs_lists=response.data.open_admission_program_lists;
            this.new_application_lists=response.data.new_application_lists;
          })
    },
  },
}
</script>

<style scoped>

</style>
