<template>
  <b-dropdown
      size="sm"
      variant="primary"
      toggle-class="btn btn-transparent-white btn-sm font-weight-bolder px-5"
      text="Quick action"
  >
    <!--begin::Navigation-->
    <div class="quick-actions navi navi-hover min-w-md-200px">
<!--      <b-dropdown-text tag="div" class="navi-header pb-1">-->
<!--&lt;!&ndash;        <span class="text-primary text-uppercase font-weight-bold">&ndash;&gt;-->
<!--&lt;!&ndash;          Add new:&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--      </b-dropdown-text>-->
      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'create')" >
        <a class="navi-link" @click="createStudent()" >
          <span class="navi-icon">
            <i class="fas fa-user-graduate"></i>
          </span>
          <span class="navi-text">
            New student
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'create')" >
        <a class="navi-link" @click="createStudent()" >
          <span class="navi-icon">
            <i class="fas fa-chalkboard-teacher"></i>
          </span>
          <span class="navi-text">
            New teacher
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('user', 'create')" >
        <a class="navi-link" @click="createStaff()" >
          <span class="navi-icon">
            <i class="fas fa-user-secret"></i>
          </span>
          <span class="navi-text">
            New staff
          </span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'create')">
        <a class="navi-link" @click="createNewAdmission()" >
          <span class="navi-icon">
            <i class="fa fa-file-contract"></i>
          </span>
          <span class="navi-text">
            New pre-registration
          </span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.dropdown-menu {
  outline: none;
}
.b-dropdown-text {
  padding: 0;
}
</style>

<script>
export default {
  name: "add-widget",
  components: {},
  methods: {
    createStudent() {
      this.$router.push({
        name: "students-create",
      });
    },
    createTeacher(){
      this.$router.push({name: 'teachers-create'});
    },
    createStaff(){
      this.$router.push({name: 'staff-create'});
    },
    createNewAdmission(){
      this.$router.push({name: 'pre-registration-create'});
    },
  },
  mounted() {

  }
};
</script>
