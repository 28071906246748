<template>
    <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
            <h5>We are currently accepting admission for following programme.</h5>

        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-2 pb-0">
            <!--begin::Table-->
            <div class="table" v-if="programs.length>1">
                <table class="table table-borderless table-vertical-center">
                    <thead>
                    <tr>
                        <th class="p-0" style="min-width: 150px"></th>
                        <th class="p-0" style="min-width: 140px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="blue text-white">
                        <th><b>Programme</b></th>
                        <!--                        <th><b>Admission Closing Date</b> </th>-->
                        <th></th>
                    </tr>
                    <tr v-for="(program,i) in programs" :key="i" v-if="program.display_in_enrollment">
                        <td class="">
                            <a
                                    href="#"
                                    class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >{{program.title}}</a
                            >
                            <small><br/>Closing Date:<br/>
                                <strong v-if="program.closing_type =='open_everytime'">N/A</strong>
                                <strong v-else>
                                    {{program.enrollment_closing_date}}
                                </strong>
                            </small>

                            <!-- <span class="text-muted font-weight-bold d-block">{{item.desc}}</span> -->
                        </td>
                        <!--                        <td>-->
                        <!--&lt;!&ndash;                            {{program.closing_type}}&ndash;&gt;-->

                        <!--                            - -->
                        <!--                        </strong>-->

                        <!--                        </td>-->

                        <td class="text-right">
                                <span class="text-muted font-weight-bold">
                                <v-btn outlined @click.prevent="apply(program)"
                                       class="btn btn-primary text-white  ">Apply Now</v-btn>
                                </span>
                        </td>


                    </tr>

                    </tbody>
                </table>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Body-->
    </div>
</template>


<script>

    import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
    import ProgramService from "@/core/services/level/program/ProgramService";

    const enrollmentService = new UserEnrollmentService();

    const programService = new ProgramService();


    export default {
        name: "Program-dashboard",
        components: {},
        data() {
            return {
                programs: [],
            };
        },
        computed: {
            user() {
                return this.$store.getters.currentUser;
            }
        },
        mounted() {
            this.getPrograms();

        },
        methods: {
            apply(program) {
                // localStorage.setItem('level_id', program.level_id)
                // localStorage.setItem('program_id', program.id)
                this.$router.push({name: "apply-enrolment-program", params: {program: program.abstract}});
            },
            getPrograms() {
                programService.paginate().then(response => {
                    this.programs = response.data.data;
                });
            },
        }
    };
</script>
