<template>
  <v-app>
    <v-row class="m-1">
      <v-col cols="12 p-9">
        <v-row>
          <v-col cols="10" style="background-color: #f3f6f9 !important;" v-if="checkIsAccessible('user', 'search-filter')">
            <form class="d-flex flex-center bg-white rounded border border-light bg-light"  @submit.prevent="searchStudent" style="background-color: #f3f6f9 !important;">
              <span class="svg-icon svg-icon-lg svg-icon-primary">
              </span>
              <v-text-field
                  dense
                  flat
                  solo
                  lass="form-control border-0 font-weight-bold pl-2"
                  type="text"
                  v-model="search.name"
                  label="Search by CRN, name, email or phone"
                  placeholder="Search by CRN, name, email or phone"
              ></v-text-field>
              <!--                          <input type="text" class="form-control border-0 font-weight-bold pl-2" placeholder="Search Goods">-->
            </form>
          </v-col>
          <v-col v-if="checkIsAccessible('user', 'create') || checkIsAccessible('pre-registration', 'create')"
              cols="2" class="p-4 text-right"  style="background-color: #f3f6f9 !important;"
          >
            <AddWidget></AddWidget>
          </v-col>
          <v-col cols="12">
            <Counter1></Counter1>
          </v-col>
          <v-col cols="12" md="4" v-if="checkIsAccessible('user', 'list')">
            <ProgramStudentChart></ProgramStudentChart>
          </v-col>
          <v-col cols="12" md="4" v-if="checkIsAccessible('user', 'list')">
            <OnlineAdmissionChart></OnlineAdmissionChart>
          </v-col>
          <v-col cols="12" md="4" v-if="checkIsAccessible('library-book', 'list')">
            <LibrarySummaryChart></LibrarySummaryChart>
          </v-col>
          <v-col cols="12">
            <Counter2></Counter2>
          </v-col>
<!--          <div class="col-md-12">-->
<!--            <dashboard-stats></dashboard-stats>-->
<!--          </div>-->
          <v-col cols="4" v-if="checkIsAccessible('contact-us', 'list')">
            <OnlineInquriesChart></OnlineInquriesChart>
          </v-col>
          <v-col cols="4" v-if="checkIsAccessible('visitor', 'list')">
            <VisitorLogChart></VisitorLogChart>
          </v-col>
          <v-col cols="4" v-if="checkIsAccessible('user', 'list')">
            <StudentsByYearChart></StudentsByYearChart>
          </v-col>
          <v-col cols="6">
            <!--begin::Slider Widget 2-->
            <div class="card card-flush carousel carousel-custom carousel-stretch slide h-xl-100" data-bs-ride="carousel" data-bs-interval="5500">
              <!--begin::Header-->
              <div class="card-header pt-5">
                <!--begin::Title-->
                <h4 class="card-title d-flex align-items-start flex-column dashboard-stats">
                  <span class="card-label fw-bold text-gray-800">Exams</span>
                  <!--                  <span class="text-gray-400 mt-1 fw-bold fs-7">Total books</span>-->
                </h4>
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body pt-6">
                <!--begin::Carousel-->
                <div class="carousel-inner">
                  <!--begin::Item-->
                  <div class="carousel-item active">
                    <!--begin::Wrapper-->
                    <div class="d-flex align-items-center mb-9">
                      <!--begin::Info-->
                      <div class="m-0">
                        <!--begin::Items-->
                        <div class="d-flex d-grid gap-2">
                          <!--begin::Item-->
                          <div class="d-flex flex-column flex-shrink-0 me-4">
                            <!--begin::Section-->
                            <span class="d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>

                              <!--end::Svg Icon-->{{ exams_stats.total_exam_count }} total
                                    </span>
                            <!--end::Section-->
                            <!--begin::Section-->
                            <span class="d-flex align-items-center text-gray-400 fw-bold fs-7">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{ exams_stats.today_exam_count }} today
                                    </span>
                            <!--end::Section-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex flex-column flex-shrink-0">
                            <!--begin::Section-->
                            <span class="d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{ exams_stats.upcoming_exam_count }} upcoming
                                    </span>
                            <!--end::Section-->
                            <!--begin::Section-->
                            <span class="d-flex align-items-center text-gray-400 fw-bold fs-7">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{ exams_stats.past_exam_count }} past
                                    </span>
                            <!--end::Section-->
                          </div>
                          <!--end::Item-->
                        </div>
                        <!--end::Items-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Action-->
                    <div class="mb-1">
                      <a href="#" class="btn btn-sm btn-light text-white me-2">View all</a>
                      <a href="#" class="btn btn-sm btn-success text-white" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">View today's</a>
                    </div>
                    <!--end::Action-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Carousel-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Slider Widget 2-->
          </v-col>
          <v-col cols="6">
            <!--begin::Slider Widget 2-->
            <div id="kt_sliders_widget_2_slider" class="card card-flush carousel carousel-custom carousel-stretch slide h-xl-100" data-bs-ride="carousel" data-bs-interval="5500">
              <!--begin::Header-->
              <div class="card-header pt-5">
                <!--begin::Title-->
                <h4 class="card-title d-flex align-items-start flex-column dashboard-stats">
                  <span class="card-label fw-bold text-gray-800">Events</span>
                  <!--                  <span class="text-gray-400 mt-1 fw-bold fs-7">Total books</span>-->
                </h4>
                <!--end::Title-->
                <!--                &lt;!&ndash;begin::Toolbar&ndash;&gt;-->
                <!--                <div class="card-toolbar">-->
                <!--                  &lt;!&ndash;begin::Carousel Indicators&ndash;&gt;-->
                <!--                  <ol class="p-0 m-0 carousel-indicators carousel-indicators-bullet carousel-indicators-active-success">-->
                <!--                    <li data-bs-target="#kt_sliders_widget_2_slider" data-bs-slide-to="0" class="ms-1"></li>-->
                <!--                    <li data-bs-target="#kt_sliders_widget_2_slider" data-bs-slide-to="1" class="ms-1"></li>-->
                <!--                    <li data-bs-target="#kt_sliders_widget_2_slider" data-bs-slide-to="2" class="ms-1 active" aria-current="true"></li>-->
                <!--                  </ol>-->
                <!--                  &lt;!&ndash;end::Carousel Indicators&ndash;&gt;-->
                <!--                </div>-->
                <!--                &lt;!&ndash;end::Toolbar&ndash;&gt;-->
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body pt-6">
                <!--begin::Carousel-->
                <div class="carousel-inner">
                  <!--begin::Item-->
                  <div class="carousel-item active">
                    <!--begin::Wrapper-->
                    <div class="d-flex align-items-center mb-9">
                      <!--begin::Info-->
                      <div class="m-0">
                        <!--begin::Subtitle-->
                        <!--                        <h4 class="fw-bold text-gray-800 mb-3">Library stats</h4>-->
                        <!--end::Subtitle-->
                        <!--begin::Items-->
                        <div class="d-flex d-grid gap-2">
                          <!--begin::Item-->
                          <div class="d-flex flex-column flex-shrink-0 me-4">
                            <!--begin::Section-->
                            <span class="d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{ event_stats.total_event_count }} total
                                    </span>
                            <!--end::Section-->
                            <!--begin::Section-->
                            <span class="d-flex align-items-center text-gray-400 fw-bold fs-7">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{ event_stats.today_event_count }} today
                                    </span>
                            <!--end::Section-->
                          </div>
                          <!--end::Item-->
                          <!--begin::Item-->
                          <div class="d-flex flex-column flex-shrink-0">
                            <!--begin::Section-->
                            <span class="d-flex align-items-center fs-7 fw-bold text-gray-400 mb-2">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{ event_stats.upcoming_event_count }} upcoming
                                    </span>
                            <!--end::Section-->
                            <!--begin::Section-->
                            <span class="d-flex align-items-center text-gray-400 fw-bold fs-7">
                                        <!--begin::Svg Icon | path: icons/duotune/general/gen057.svg-->
                                        <span class="svg-icon svg-icon-6 svg-icon-gray-600 me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                <path
                                                    d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </span>
                              <!--end::Svg Icon-->{{event_stats.past_event_count}} past
                                    </span>
                            <!--end::Section-->
                          </div>
                          <!--end::Item-->
                        </div>
                        <!--end::Items-->
                      </div>
                      <!--end::Info-->
                    </div>
                    <!--end::Wrapper-->
                    <!--begin::Action-->
                    <div class="mb-1">
                      <a href="#" class="btn btn-sm btn-light text-white me-2">View all</a>
                      <a href="#" class="btn btn-sm btn-success text-white" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">View today's</a>
                    </div>
                    <!--end::Action-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Carousel-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Slider Widget 2-->
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-app>

</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import Enrolment from '../pages/enrolment/enrolmentDashboard'
import AvailableProgram from '../pages/program/ProgramDashboard'


import AddWidget from "@/view/content/dropdown/AddWidget.vue";
import Counter1 from "../content/stats/dashboard/Counter1";
import Counter2 from "../content/stats/dashboard/Counter2";
import ProgramStudentChart from "../content/stats/dashboard/ProgramStudentsChart";
import OnlineInquriesChart from "../content/stats/dashboard/OnlineInquiriesChart";
import StudentsByYearChart from "../content/stats/dashboard/StudentsByYearChart";
import OnlineAdmissionChart from "../content/stats/dashboard/OnlineAdmissionChart";
import VisitorLogChart from "../content/stats/dashboard/VisitorLogChart";
import LibrarySummaryChart from "../content/stats/dashboard/LibrarySummaryChart";

import Widget7 from '../content/widgets/stats/Widget7'
import Widget12 from '../content/widgets/stats/Widget12'
import Widget13 from '../content/widgets/stats/Widget13'
import WidgetList1 from '../content/widgets/list/Widget1'
import WidgetList2 from '../content/widgets/list/Widget2'
import WidgetList3 from '../content/widgets/list/Widget3'
import WidgetEngage3 from '../content/widgets/engage/Widget1'
import CounterWidget from '../content/widgets/counter/CounterWidget'
import MixedWidget from '../content/widgets/mixed/Widget1'
import DropDownWidget1 from '../content/dropdown/Dropdown1'

import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import ProgramService from "@/core/services/level/program/ProgramService";
import ConfigService from "@/core/services/config/ConfigService";
import DashboardStats from "@/view/pages/Stats/DashboardStats";
import DashboardExamStats from "@/view/pages/Stats/DashboardExamStats.vue";
import UserService from "@/core/services/user/UserService";
import DashboardClassStats from "@/view/pages/Stats/DashboardClassStats.vue";
import DashboardVisitorStats from "@/view/pages/Stats/DashboardVisitorStats.vue";
import DashboardService from "@/core/services/dashboard/DashboardService";

const userService = new UserService();
import {mapActions} from "vuex";

const enrollmentService = new UserEnrollmentService();
const dashboard = new DashboardService();

const programService = new ProgramService();

const configService = new ConfigService();

export default {
  name: "dashboard",
  components: {
    Enrolment,
    AvailableProgram,
    DashboardStats,
    DashboardExamStats,
    DashboardClassStats,
    DashboardVisitorStats,
    AddWidget,
    Counter1,
    Counter2,
    ProgramStudentChart,
    OnlineInquriesChart,
    StudentsByYearChart,
    OnlineAdmissionChart,
    LibrarySummaryChart,
    VisitorLogChart,
    Widget7,
    Widget12,
    Widget13,
    WidgetList1,
    WidgetList2,
    WidgetList3,
    WidgetEngage3,
    CounterWidget,
    MixedWidget,
    DropDownWidget1

  },
  data() {
    return {
      stats: {},
      programs: [],
      search: {
        name: '',
        type: 'student',
        limit: 25,

      },

      exams_stats:{
        today_exam_count:0,
        upcoming_exam_count:0,
        total_exam_count:0,
        past_exam_count:0,
      },
      event_stats:{
        today_event_count:0,
        upcoming_event_count:0,
        total_event_count:0,
        past_event_count:0,
      }
    };
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);
    this.getStats();
    this.getExamStats();
    this.getEventStats();
    if (this.user.type == "admin") {
      this.getStats();
     /* this.getExamStats();
      this.getEventStats();*/
    }
    if (this.currentUser.type == 'admin')
      this.getPrograms();

  },
  methods: {
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    searchStudent() {
      this.$router.push({name: 'students', query: this.search});
    },
    getPrograms() {
      programService.paginate().then(response => {
        this.programs = response.data.data;
      });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    getStats() {
      enrollmentService.getStats(this.user.id).then(response => {
        this.stats = response.data.adminStats;
        this.upcoming_exams=response.data.upcoming_exam_lists;
        this.show_dashboard = true
      });
    },
    getExamStats() {
      dashboard
          .getExamStats()
          .then((response) => {
            this.exams_stats=response.data.exams_stats;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    getEventStats() {

      dashboard
          .getEventStats()
          .then((response) => {
            this.event_stats=response.data.event_stats;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    }
  }
};
</script>

<!--<style scoped>-->
<!--.theme&#45;&#45;light.v-application {-->
<!--  background: none !important;-->
<!--  /*color: rgba(0, 0, 0, 0.87);*/-->
<!--}-->
<!--</style>-->

