<template>
  <div>
    <div class="border-graph">
      <h4>Yearly student stats</h4>
      <apexchart v-if="!loading" width="100%" height="250" type="bar" :options="chartOptions" :series="series" stacked="true"></apexchart>
    </div>

  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
const dashboard=new DashboardService();
    export default {
        name: 'StudentsByYear',
        data: function() {
          return {
            loading:false,
            chartOptions: {
              chart: {
                id: 'vuechart-example',
                stacked: true,
              },
              xaxis: {
                categories: []
              },
            },
            series: [
            {
              name: 'BIT',
              data: [600, 55, 41, 100, 22]
            },
              {
              name: 'BIM',
              data: [600, 500, 20, 90, 13]
            },
              {
              name: 'BBA',
              data: [600, 17, 15, 134, 21]
            }],
          }
        },
      methods:{
        getAllProgramStats(){
          this.loading=true;
          dashboard
              .getProgramStats()
              .then((response) => {
                let studentProgramCountByYears=response.data.student_program_count_by_years;
                let program_data=[];
                let academic_year=[];

                studentProgramCountByYears.forEach(function (item) {

                  let data={
                    'name':item.name,
                    'data':item.value,
                  }
                  program_data.push(data);
                  academic_year.push(item.year);
                });

                this.series=program_data;
                this.chartOptions.xaxis.categories=academic_year[0];

              })
              .catch((err) => {

              })
              .finally(() => {
                this.loading=false;
              });
        },
      },
      mounted() {
        this.getAllProgramStats();
      }
    }
</script>