<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card  card-stretch dashboard-count-box">
          <div class="card-header">
            <div class="row">
              <v-col md="5">
                <v-select
                    class="form-control"
                    outlined clearable
                    :items="programs"
                    v-model="search.program_id" @change="getClassStats"
                    dense  v-on:keyup.enter="getClassStats()"
                    item-text="title"
                    item-value="id"
                    label="Program"
                >
                </v-select>
              </v-col>
            </div>
          </div>
          <div class="card-body d-flex align-items-center py-0">
            <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                <a href="#" class="card-title font-weight-bolder text-dark-75 font-size-h5 mt-2 mb-2 ml-3 text-hover-primary">
                  Total ongoing class
                </a>
                <span class="font-weight-bold font-size-h4  font-size-lg mr-3" style="margin-left:40px ">
                 {{stats.ongoing_class_count }}
                </span>
            </div>
          </div>
        </div>
<!--      <router-link to="/academic-class">
      </router-link>-->
    </div>
  </div>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import ProgramService from "@/core/services/level/program/ProgramService";
const programService=new ProgramService;
const userService = new UserService();
export default {
  data(){
    return{
      search:{
        program_id:'',
      },
      stats:{
        ongoing_class_count:0,
      },
      programs:[],
    }
  },
  methods: {
    getClassStats(){
      userService
          .getClassStats(this.search)
          .then((response) => {
              this.stats.ongoing_class_count=response.data.ongoing_class_count;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
  },
  mounted() {
    this.getPrograms();
    this.getClassStats();
  }
}
</script>