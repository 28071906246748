import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class UserParentService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/enrollment`;

      }

      paginate(userId) {
            let url = `${this.#api}/${userId}/parent`;
            return apiService.get(url);
      }

      update(userId,parentId, data) {
            let url = `${this.#api}/${userId}/parent/${parentId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
            let url = `${this.#api}/${userId}/parent`
            return apiService.post(url, data);

      }

      show(userId, parentId) {
            let url = `${this.#api}/${userId}/parent/${parentId}`
            return apiService.get(url);
      }

      delete(userId, parentId) {
            let url = `${this.#api}/${userId}/parent/${parentId}`
            return apiService.delete(url);
      }

      filter(data={}){
            let url = `${this.#api}/parent/filter`;
            let param = {params: data};
            return apiService.query(url, param);
        }

      bulkUpdatePassword(data){
            let url = `${this.#api}/parent/bulk/change-password`;
            return apiService.post(url, data);
        }
}
