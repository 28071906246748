<template>
  <div>
    <div class="border-graph">
      <h4>Visitors Log</h4>
      <apexchart v-if="!loading" width="100%" height="250px" type="area" :options="chartOptions" :series="series"></apexchart>
    </div>

  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
const dashboard=new DashboardService();
    export default {
        name: 'VisitorLogChart',
        data: function() {
          return {
            loading:false,
            chartOptions: {
              chart: {
                id: 'vuechart-example-two',
              },
              xaxis: {
                type: 'datetime',
              },
              yaxis: {
                opposite: true
              },
              legend: {
                horizontalAlign: 'left'
              },
              labels: [],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight'
              },
            },
            series: [
              {
                name: "Visitor",
                data: []
              },
            ],
          }
        },
        methods:{
          getVisitorStats(){
            this.loading=true;
            dashboard
                .getVisitorStats()
                .then((response) => {
                  let visitorsStats=response.data.visitors;
                  let count=[];
                  let date_labels=[];

                  visitorsStats.forEach(function (item) {
                    date_labels.push(item.date);
                    count.push(item.value);
                  });

                  this.series[0].data=count;
                  this.chartOptions.labels=date_labels;

                })
                .catch((err) => {

                })
                .finally(() => {
                  this.loading=false;
                });
          },
        },
      mounted() {
          this.getVisitorStats();
      }
    }
</script>