<template>
    <div>
        <div class="card card-custom card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
        >My Application(s)</span
        >

                </h3>

            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-2 pb-0">
                <!--begin::Table-->
                <div class="table" v-if="enrollments.length>0">

                    <table class="table table-borderless table-vertical-center">
                        <thead class="text-left blue text-white">
                        <!--                        <th>Applicant Name</th>-->
                        <!--                        <th>Email</th>-->
                        <!--                        <th>Phone</th>-->
                        <!--                        <th>Mobile</th>-->
                        <th>Programme</th>
                        <!--                        <th>DOB</th>-->
                        <!--                        <th>Gender</th>-->
                        <!--                        <th>Nationality</th>-->
                        <!--                        <th>Entrance Date</th>-->
                        <th>Status</th>
                        <!--                        <th><h4>Payment Status</h4></th>-->
                        <!--                        <th>Payment Method</th>-->
                        <th>Action</th>
                        </thead>
                        <tbody class="text-left" v-if="enrollments.length>0">
                        <tr v-for="(enrollment, index) of enrollments" :key="index">
                            <!--                            <td>{{enrollment.full_name}}</td>-->
                            <!--                            <td>{{enrollment.email}}</td>-->
                            <!--                            <td>{{enrollment.phone}}</td>-->
                            <!--                            <td>{{enrollment.mobile}}</td>-->
                            <td class="cursor-pointer"><span @click="openDetailDialog(enrollment.id)">{{enrollment.program ? enrollment.program : 'Not Selected'}}</span>
                            </td>
                            <!--                            <td>-->
                            <!--                                <span>{{(enrollment.exam_date)?enrollment.exam_date:"N/A"}}</span>-->
                            <!--                            </td>-->
                            <td>
                                <span :class="enrollment.is_submitted ? 'badge badge-info mr-2':'badge badge-warning mr-2 mt-2'"
                                >{{enrollment.is_submitted ? 'Complete':'Pending Submission'}}</span>
                                <span :class="enrollment.is_payment_completed ? 'badge badge-info mr-2':'badge badge-warning mr-2 mt-2'"
                                >{{enrollment.is_submitted ? 'Paid':'Pending Payment'}}</span>
                            </td>
                            <!--                            <td>-->
                            <!--                            </td>-->
                            <!--                            <td>-->
                            <!--                              <span class="badge badge-primary"> {{enrollment.payment_method ? enrollment.payment_method.toUpperCase():'N/A'}}-->
                            <!--                            </span></td>-->
                            <td>
                                <v-menu top :close-on-click="closeOnClick">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="badge badge-info text-white" text v-bind="attrs" v-on="on">
                                          <span>
                                             <i class="flaticon-more-1"></i> Manage
                                          </span>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-if="user.type == 'admin' &&
                                         enrollment.payment_method == 'cash' && !enrollment.is_payment_completed"
                                        >
                                            <v-list-item-title class="cursor-pointer">
                                              <span @click="offlinePayement(enrollment.id)">
                                                <i class="fas fa-money-bill"></i>
                                                Offline Payment
                                              </span>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-title class="cursor-pointer">
                                              <span @click="editEnrollment(enrollment.id)">
                                                <i class="fas fa-edit"></i>
                                                Edit Enrollment
                                              </span>
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-title class="cursor-pointer">
                                              <span @click="openDetailDialog(enrollment.id)">
                                                <i class="fas fa-eye"></i>
                                                View Detail
                                              </span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!--end::Table-->

                <div v-else>
                    <p> You do not have any pending or completed admission form. Please apply one of the program you are
                        interested in. </p>
                </div>
            </div>
            <!--end::Body-->
        </div>

        <enrollment-detail
                :dialog="dialog"
                :enrollment_id="enrollment_id"
                @close_dialog="closeDialog"
        ></enrollment-detail>
    </div>

</template>


<script>

    import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
    import ProgramService from "@/core/services/level/program/ProgramService";
    import EnrollmentDetail from "./Detail";

    const enrollmentService = new UserEnrollmentService();

    const programService = new ProgramService();


    export default {
        name: "dashboard",
        components: {

            EnrollmentDetail,
        },
        data() {
            return {
                stats: {},
                programs: [],
                enrollments: [],
                closeOnClick: true,
                dialog: false,
                enrollment_id: null
            };
        },
        computed: {
            user() {
                return this.$store.getters.currentUser;
            }
        },
        mounted() {
            this.getEnrollments();

        },
        methods: {
            getLevels() {
                levelService.all().then(response => {
                    this.levels = response.data;
                    this.getPrograms();
                });
            },
            getEnrollments() {
                enrollmentService
                    .paginate()
                    .then(response => {
                        this.enrollments = response.data.data;
                    })
                    .catch(error => {
                        //console.log(error);(error);
                    });
            },
            openDetailDialog(id) {
                this.dialog = true;
                this.enrollment_id = id;
            },
            closeDialog() {
                this.dialog = false;
                this.enrollment_id = null;
            },
            editEnrollment(id) {
                this.$router.push({name: "apply-enrolment-update", params: {id: id}});
            },
            offlinePayement(id) {
                this.$router.push({name: "offline-payement", params: {id: id}});
            },

        }
    };
</script>
