<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="12">
            <h4>Library stats</h4>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <apexchart v-if="!loading" width="100%" height="300" type="donut" :options="chartOptions" :series="series"></apexchart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
const dashboard=new DashboardService();
    export default {
        name: 'LibrarySummaryChart',
        data: function() {
          return {
            loading:false,
            series: [],
            chartOptions: {
              chart: {
                id: 'library',
                type: 'donut',
              },
              labels: [],
              dataLabels: {
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex]
                },
              },
              noData: {
                text: "No data found",
                align: "center",
                verticalAlign: "middle",
              },
            },
          }
        },
      methods:{
          getAllLibraryStats(){
            this.loading=true;
            dashboard
                .getLibraryStat()
                .then((response) => {
                  let libraryStats=response.data.library_counts;
                  let label=[];
                  let count=[];

                  libraryStats.forEach(function (item) {
                    label.push(item.label);
                    count.push(item.value);
                  });
                 this.chartOptions.labels=label;
                 this.series=count;
                })
                .catch((err) => {

                })
                .finally(() => {
                  this.loading=false;
                });

          }
      },
      mounted() {
          this.getAllLibraryStats();
      }
    }
</script>
