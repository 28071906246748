<template>
  <div>
    <v-card >
      <v-card-title>
        <v-row>
          <v-col md="8">
            <h4>Admission by program</h4>
          </v-col>
          <v-col md="4" style="margin-top: 12px;">
            <v-select @change="getOnlineAdmissionStats" v-model="academic_year" item-value="id" item-text="year" dense outlined :items="years"></v-select>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <apexchart v-if="!loading" width="100%" height="250" type="area" :options="chartOptions" :series="series"></apexchart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
const academicYear=new AcademicYearService();
const dashboard = new DashboardService();
export default {
    name: 'OnlineAdmissionChart',
    data: function() {
          return {
            loading:false,
            chartOptions: {
              chart: {
                id: 'online-admission',
              },
              xaxis: {
                type: 'datetime',
              },
              yaxis: {
                opposite: true
              },
              legend: {
                horizontalAlign: 'left'
              },
              labels: [],
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'straight'
              },
              noData: {
                text: this.loading ? "Loading...":"No Data found",
                align: "center",
                verticalAlign: "middle",
              },
            },
            series: [],
            years:[],
            academic_year:null,
          }
        },
    methods:{
        getOnlineAdmissionStats(){
          this.loading=true;
          dashboard
              .getAdmissionStats()
              .then((response) => {
                let admissionProgramStats=response.data.admission_program_stats;
                if(admissionProgramStats) {
                  let program_data=[];
                  let years=[];
                  admissionProgramStats.forEach(function (item) {
                    let data={
                      'name':item.name,
                      'data':item.value,
                    }
                    program_data.push(data);
                    years.push(item.year);
                  });
                  this.series=program_data;
                  this.chartOptions.labels=years[0];
                }
              }).catch(() => {})
              .finally(() => {
                this.loading=false;
              });
        },
      getAllAcademicYear(){
        academicYear
            .all()
            .then((response) => {
              this.years=response.data;
              if(this.years) {
                this.years.forEach(item => {
                  if(item.is_current_year) {
                    this.academic_year = item;
                  }
                })
              }
              if(this.academic_year) {
                this.getOnlineAdmissionStats();
              }
            })
            .catch((err) => {

            })
            .finally(() => {

            });

      },
      yearText(){
        if(this.academic_year){
          return  "("+this.academic_year.year+")";
        }else{
          return null;
        }
      },
    },
  mounted() {
    this.getAllAcademicYear();
  }
}
</script>
