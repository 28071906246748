import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class UserEducationService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/enrollment`;

      }

      paginate(userId) {
            let url = `${this.#api}/${userId}/education`;
            return apiService.get(url);
      }

      update(userId,educationId, data) {
            let url = `${this.#api}/${userId}/education/${educationId}`
            return apiService.put(url, data);

      }

      store(userId, data) {
                  let url = `${this.#api}/${userId}/education`
            return apiService.post(url, data);

      }

      show(userId, educationId) {
            let url = `${this.#api}/${userId}/education/${educationId}`
            return apiService.get(url);
      }

      delete(userId, educationId) {
            let url = `${this.#api}/${userId}/education/${educationId}`
            return apiService.delete(url);
      }


}
