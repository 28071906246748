<template>
  <div class="row">
    <div class="col-md-6">
      <router-link to="/exams">
        <div class="card  card-stretch dashboard-count-box">
          <a href="#"
               class="card-title font-weight-bolder text-dark-75 font-size-h5 mt-2 mb-2 ml-3 text-hover-primary">
              Upcoming exams</a>

            <v-list-item v-show="upcoming_exams_lists && upcoming_exams_lists.length > 0"  v-for="(exam,index) in upcoming_exams_lists" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold h6">
                      {{exam.exam_title}} ({{exam.start_date}})
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle>
                  <div class="text-secondary">
                    <span class="font-weight-bold">  Grade :</span>  <span class="font-weight-medium">{{exam.grade_title}}</span>
                  </div><div class="text-secondary">
                    <span class="font-weight-bold">  Year :</span>  <span class="font-weight-medium">{{exam.academic_year_title}}</span>
                  </div>
                  <div class="text-secondary">
                    <span class="font-weight-bold">  Program :</span>  <span class="font-weight-medium">{{exam.program_title}}</span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          <span v-show="upcoming_exams_lists && upcoming_exams_lists.length == 0" class="mt-3 mb-3 ml-4">
            No upcoming exam
          </span>


        </div>
      </router-link>

    </div>
    <div class="col-md-6">
      <router-link to="/exams">
        <div class="card  card-stretch dashboard-count-box">
          <a href="#" class="card-title font-weight-bolder text-dark-75 font-size-h5 mt-2 mb-2 ml-3 text-hover-primary">
            Recently completed exams</a>

            <v-list-item v-show="recently_exam_lists &&  recently_exam_lists.length > 0"  v-for="(recent_exam,index) in recently_exam_lists" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold h6">
                    {{recent_exam.exam_title}} ({{recent_exam.start_date}})
                    </span>
                </v-list-item-title>

                <v-list-item-subtitle>
                  <div class="text-secondary">
                    <span class="font-weight-bold">  Grade :</span>  <span class="font-weight-medium">{{recent_exam.grade_title}}</span>
                  </div><div class="text-secondary">
                    <span class="font-weight-bold">  Year :</span>  <span class="font-weight-medium">{{recent_exam.academic_year_title}}</span>
                  </div>
                  <div class="text-secondary">
                    <span class="font-weight-bold">  Program :</span>  <span class="font-weight-medium">{{recent_exam.program_title}}</span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          <span v-show="recently_exam_lists && recently_exam_lists.length == 0" class="mt-3 mb-3 ml-4">
            No recent exam
          </span>


        </div>
      </router-link>

    </div>
  </div>
</template>
<script>

export default {
props:['upcoming_exams_lists','recently_exam_lists'],
  mounted() {
  }
}
</script>