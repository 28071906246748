<template>
  <div>
    <div class="row">
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('user', 'list')">
        <router-link to="/students" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
          <div class="card-body">
              <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fa-solid fa-users font-white"></i>
              </span>
            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_active_student || 0 }}</div>
            <div class="text-inverse-primary fw-semobold fs-7">Students</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('teacher', 'list')">
        <router-link to="/teachers" class="card-xl-stretch mb-xl-8 bg-primary card hoverable">
          <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-user-tie font-white"></i>
                </span>
            <div class="text-inverse-primary fw-bold fs-2 mb-2 mt-5">{{ stats.total_active_teacher || 0 }}</div>
            <div class="text-inverse-primary fw-semobold fs-7">Teachers</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('staff', 'list')">
        <router-link to="/user/admin" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
          <div class="card-body">
                <span class="svg-icon-gray-100 svg-icon svg-icon-3x mx-n1">
                  <i class="fa fa-user-shield font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.total_active_staff || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Staffs</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('program', 'list')">
        <router-link to="/program" class="card-xl-stretch mb-xl-8 bg-primary card hoverable">
          <div class="card-body">
                <span class="svg-icon-gray-100 svg-icon svg-icon-3x mx-n1">
                  <i class="fa-solid fa-award font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.total_active_program || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Programs</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2" v-if="checkIsAccessible('academic-class', 'list')">
<!--        <a href="#" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
          <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-chalkboard-teacher font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5"></div>
            <div class="text-inverse-dark fw-semobold fs-7">Classrooms</div>
          </div>
        </a>-->
        <router-link :to="{name:'academic-class'}" class="card-xl-stretch mb-xl-8 bg-dark card hoverable">
          <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-chalkboard-teacher font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.total_active_class || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Classrooms</div>
          </div>
        </router-link>
      </div>
      <div class="col-xl-2 col-md-2"  v-if="checkIsAccessible('career', 'list')">
        <router-link :to="{name:'career'}" class="card-xl-stretch mb-xl-8 bg-primary card hoverable">
          <div class="card-body">
                <span class="svg-icon-white svg-icon svg-icon-3x mx-n1">
                  <i class="fas fa-briefcase font-white"></i>
                </span>
            <div class="text-inverse-dark fw-bold fs-2 mb-2 mt-5">{{ stats.total_opened_job || 0 }}</div>
            <div class="text-inverse-dark fw-semobold fs-7">Vacancy</div>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
import DashboardService from "@/core/services/dashboard/DashboardService";
const dashboard=new DashboardService();
export default {
  name: "Counter1",
  data() {
    return {
      stats: {
        total_opened_job: null,
        total_parent: null,
        total_program: null,
        total_staff: null,
        total_student: null,
        total_teacher: null,
        total_active_class:null,
      },
      passed_out_student_stats:null,
      program_stats:[],
      library_stats:{
        total_book_counts:null,
        total_issued_counts:null,
      },
      open_admission_programs_lists:[],
      new_application_lists:[],
    }
  },
  mounted() {
    this.getStats();
  },
  methods: {
    getStats() {
      dashboard
          .getStats()
          .then((response) => {
            this.stats = response.data.adminStats;
            this.program_stats = response.data.programStudentStats;
          })
    },
  },
}
</script>